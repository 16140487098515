import React from 'react'
import indian from "../Assets/india-img.webp";
import italy from "../Assets/italy.webp";
import paris from "../Assets/paris.webp";
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const Location = () => {
  return (
    <>
      <div className="bg-[#ffecec] shadow-location mx-44  max-sm:mx-0 max-md:mx-20 mt-20 py-6 text-center">
        <h2 className="text-2xl lg:text-4xl md:text-2xl  m-2 font-bold text-[#050748]">
          Our Office Locations
        </h2>
      </div>
      <div className="flex  max-lg:flex-wrap max-lg:gap-1 justify-center my-10 gap-10">
        <div className="w-[20rem] px-5 py-6 bg-white border-b-4 border-b-[#b0bdff] rounded-lg shadow-location">
          <div className="bg-[#fbfbfb] text-center">
            <img
              src='/chandigarh.webp'
              alt="location"
              className="w-[300px] h-[240px] img-fluid mx-auto d-block"
            />
          </div>
          <div className="mt-4">
            <h4 className="text-2xl font-bold text-[#050748]">India</h4>
            <h6 className="mt-2 text-base font-bold text-[#050748]">
              Chandigarh Head Office
            </h6>
            <p className="text-lg text-[#6a6a8e] mt-4 text-justify">

              SCO 54-56, 2nd Floor, Near Mukat Hospital, 34A Sector, Chandigarh, 1600022
            </p>
            <ul className="text-lg text-[#6a6a8e] mt-4">
              <li>
                <Link
                  to="mailto:info@futuretouch.com"
                  className="flex items-center gap-3"
                >
                  <FaEnvelope /> info@futuretouch.in
                </Link>
              </li>
              <li>
                <Link
                  to="tel:+91-7056937000"
                  className="flex items-center gap-3"
                >
                  <FaPhoneAlt /> +91-7056937000
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-[20rem] px-5 py-6 bg-white border-b-4 border-b-[#b0bdff] rounded-lg shadow-location">
          <div className="bg-[#fbfbfb] text-center">
            <img
              src='/banglore.jpg'
              alt="location"
              className="w-[300px] h-[240px] object-cover img-fluid mx-auto d-block"
            />
          </div>

          <div className="mt-4 h-auto flex flex-col">
            <h4 className="text-2xl font-bold text-[#050748]">India</h4>
            <h6 className="mt-2 text-base font-bold text-[#050748]">
              Bangalore Branch Office
            </h6>
            <p className="text-lg text-[#6a6a8e] mt-4 text-justify ">
              59, 2nd Floor, Paramahansa Yogananda Rd, Link Rd 4, above Arun Cadd, Indiranagar, Bengaluru, Karnataka 560038
            </p>
            <div className="mt-8">
              <ul className="text-lg text-[#6a6a8e] flex flex-col gap-2">
                <li>
                  <Link to="mailto:info@futuretouch.com" className="flex items-center gap-3">
                    <FaEnvelope /> info@futuretouch.in
                  </Link>
                </li>
                <li>
                  <Link to="tel:+91-8050080035" className="flex items-center gap-3">
                    <FaPhoneAlt /> +91-8050080035
                  </Link>
                </li>
              </ul>
            </div>
          </div>




        </div>
        <div className="w-[20rem] px-5 py-6 bg-white border-b-4 border-b-[#b0bdff] rounded-lg shadow-location">
          <div className="bg-[#fbfbfb] text-center">
            <img
              src='/texas.webp'
              alt="location"
              className="w-[300px] h-[240px] mx-auto d-block"
            />
          </div>

          <div className="mt-4">
            <h4 className="text-2xl font-bold text-[#050748]">
              United States
            </h4>
            <h6 className="mt-2 text-base font-bold text-[#050748] ">
              Texas Branch Office
            </h6>
            <p className="text-lg text-[#6a6a8e] mt-4 text-justify">
              1400 Avery Ranch Blvd, Ste C200 Austin, Texas-78717
            </p>
            <ul className="text-lg text-[#6a6a8e] mt-16">
              <li>
                <Link
                  to="mailto:info@futuretouch.com"
                  className="flex items-center gap-3"
                >
                  <FaEnvelope /> info@futuretouch.in
                </Link>
              </li>
              <li>
                <Link
                  to="tel:+1-5126406568"
                  className="flex items-center gap-3"
                >
                  <FaPhoneAlt /> +1-5126406568
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-[20rem] px-5 py-6 bg-white border-b-4 border-b-[#b0bdff] rounded-lg shadow-location">
          <div className="bg-[#fbfbfb] text-center">
            <img
              src='/dubai.jpeg'
              alt="dubai"
              className="w-[300px] h-[240px] mx-auto d-block"
            />
          </div>

          <div className="mt-4">
            <h4 className="text-2xl font-bold text-[#050748]">
            United Arab Emirates

            </h4>
            <h6 className="mt-2 text-base font-bold text-[#050748] ">
              Dubai Branch Office
            </h6>
            <p className="text-lg text-[#6a6a8e] mt-4 text-justify">
            RAKEZ Compass Al Shohada Road Al Hamra Industrial Zone - FZ - Ras Al Khaimah
            </p>
            <ul className="text-lg text-[#6a6a8e] mt-16">
              <li>
                <Link
                  to="mailto:info@futuretouch.com"
                  className="flex items-center gap-3"
                >
                  <FaEnvelope /> info@futuretouch.in
                </Link>
              </li>
              <li>
                <Link
                  to="tel:+97158559552"
                  className="flex items-center gap-3"
                >
                  <FaPhoneAlt />+971-58559552
                </Link>
              </li>
            </ul>
          </div>
        </div>

        
      </div>
    </>
  )
}

export default Location
